.profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .profile-container h2 {
    font-weight: bold;
    
  }
  
  .profile-container .img-fluid {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border: 3px solid balck;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-radius: 25px;
  }
  
  .btn-secondary {
    background-color: #6c757d;
    border-radius: 25px;
  }
  
  .form-control {
    border-radius: 10px;
    padding: 10px;
  }
  .profile-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 1rem;
  }
  /* Title style */
.profile-title {
    color: #333; /* Darker title color */
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
  }