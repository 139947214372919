.top-reviews-container {
    
    padding: 2rem;
  }
  
  .review-card-dark {
    background-color: #333 !important;
    color: white;
    border: none;
    margin-top: 1rem;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .review-card-dark a {
    color: white;
    text-decoration: none;
  }
  
  .review-card-dark a:hover {
    color: #007bff;
    text-decoration: none;
  }
  
  .review-card-dark .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .review-card-dark .card-title {
    font-size: 1.2rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .review-card-dark img {
    object-fit: cover;
    height: 300px;
    width: 100%;
  }
  .title {
    margin-bottom: 30px;
    font-size: xxx-large;
  }