footer {
    background-color: #1f1f1f;
    color: #dcdcdc;
    padding: 40px 0;
  }
  
  footer h5 {
    color: #fff;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  footer p, footer a {
    color: #dcdcdc;
    font-size: 0.9rem;
    margin-bottom: 10px;
    text-decoration: none;
  }
  
  footer a:hover {
    color: #007bff;
  }
  
  .footer-bottom {
    background-color: #111;
    color: #888;
    padding: 15px 0;
    font-size: 0.85rem;
  }
  
  .footer-bottom a {
    color: #fff;
    margin-left: 15px;
  }
  