.review-container {
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .review-text {
    margin-bottom: 1rem;
    white-space: pre-line; /* Ensures line breaks and paragraphs are respected */
  }
  
  .comment-section {
    background-color: #f9f9f9;
    border-radius: 5px;
  }
  
  .affiliate-links a {
    color: #007bff;
    text-decoration: none;
  }
  
  .affiliate-links a:hover {
    text-decoration: underline;
  }
  
  .list-unstyled {
    padding-left: 0;
  }
  .review-image2 {
    width: 100%;  /* Makes sure the image takes up the full width of its container */
    max-width: 400px; /* Adjust the maximum width of the image */
    height: 25%;  /* Maintains aspect ratio */
    display: block;
    margin: 0 auto 1rem auto;  /* Centers the image and adds bottom margin */
    border-radius: 100px; /* Optional: adds rounded corners to the image */
  }