.about-us-section {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .about-us-section h1 {
    font-weight: bold;
    color: #333;
  }
  
  .about-us-section h3 {
    font-weight: bold;
  
  }
  
  .about-us-section .card {
    border-radius: 15px;
    margin-bottom: 20px;
  }
  
  .about-us-section .card-body {
    padding: 30px;
  }
  
  .about-us-section .text-center {
    text-align: center;
  }
  
  .about-us-section .btn-primary {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    font-size: 1.2rem;
    border-radius: 50px;
  }
  
  .about-us-section .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .about-us-section p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
  }
  .about-image {
    max-width: 150px;
    max-height: 150px;
    margin-bottom: 20px;
  }
  