.content-section {
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .review-card {
    margin-top: 1rem;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .review-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .card-image {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 200px;
    object-fit: cover;
  }
  
  .review-card .card-title {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .review-card .card-text {
    color: white;
    text-decoration: none;
  }
  .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .createdon {
    color: white;
  }
  .custom-link {
    text-decoration: none;
    color: inherit; /* Ensures the link uses the surrounding text color */
  }
  .custom-link:hover {
    text-decoration: none;
  }
  
  @media (max-width: 768px) {
    .content-section {
      justify-content: center;
    }
    .review-card {
      margin-bottom: 20px;
    }
  }
  