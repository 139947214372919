.carousel-caption-custom {
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
  }
  
  .carousel-caption-custom h3 {
    font-size: 2rem;
    font-weight: bold;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  }
  
  .carousel-caption-custom p {
    font-size: 1.1rem;
    text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
  }
  
  .carousel-item img {
    border-radius: 10px;
    object-fit: cover;
  }
  .hero-image {
    height: 500px; /* Adjust the height */
    object-fit: cover; /* Ensure images fit within the carousel */
  }
  
  .carousel-caption {
    background-color: rgba(0, 0, 0, 0.5); /* Add a dark background to text for readability */
    padding: 10px;
    border-radius: 10px;
  }
  
  @media (max-width: 768px) {
    .carousel-caption-custom h3 {
      font-size: 1.5rem;
    }
  
    .carousel-caption-custom p {
      font-size: 1rem;
    }
    
      
  }
  