body {
  margin: 0;
  font-family: 'Roboto', sans-serif; /* or use 'Arial', 'Helvetica', or other sans-serif fonts */
  font-size: 16px; /* adjust size to your preference */
  line-height: 1.6; /* gives breathing space between lines */
  color: #333; /* dark gray for text */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
