.review-card {
  background-color: #333;
  color: white;
  border: none;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.review-card a {
  color: white;
  text-decoration: none !important; /* Force remove underline */
}

.review-card a:hover {
  color: #007bff; /* Blue on hover */
  text-decoration: none !important; /* Ensure underline is removed on hover */
}

.review-card .card-title {
  font-size: 1.2rem;
  line-height: 1.4;
  max-height: 3em; /* Approximate 2 lines of text */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.review-card .card-title:hover {
  color: #0066ff; /* Hover color */
  text-decoration: none !important; /* Ensure no underline on title hover */
}

.review-card img {
  width: 100%;
  height: 200px; /* Adjust height as needed */
  object-fit: cover; /* Maintain aspect ratio */
}

.review-card .card-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.review-card .card-text {
  margin-top: auto;
  color: white;
  text-decoration: none !important; /* Remove underline from text */
}

