/* Brand Styling */
.navbar-brand {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 1.8rem;
  color: #007bff;
  padding-left: 10px;
}

/* Search Bar Styling */
.pill-search {
  display: flex;
  border-radius: 25px;
  background-color: #f1f1f1;
  padding: 5px;
  flex-grow: 1;
  max-width: 600px;
  margin-right: 20px;
}

.search-input {
  border-radius: 25px 0 0 25px;
  border: none;
  padding: 0.6rem 1rem;
  background-color: #e9ecef;
  color: #495057;
  flex-grow: 1;
}

.pill-search-btn {
  border-radius: 0 25px 25px 0;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0.6rem 1rem;
  font-weight: bold;
}

.pill-search-btn:hover {
  background-color: #0056b3;
  color: #fff;
}

/* Navbar Styling */
.secondary-navbar {
  background-color: #007bff;
  padding: 10px 0;
}

.secondary-navbar .nav-link {
  color: #ffffff;
  font-size: 1.1rem;
  margin: 0 15px;
}

.secondary-navbar .nav-link:hover {
  color: #f1f1f1;
  font-weight: bold;
}

/* Button Styling */
.btn-outline-primary, .btn-primary {
  border-radius: 25px;
  padding: 0.5rem 1.2rem;
  font-weight: bold;
}

.btn-primary {
  background-color: #007bff;
  border: none;
}

.btn-outline-primary {
  color: #007bff;
  border: 2px solid #007bff;
}

.btn-outline-primary:hover {
  background-color: #007bff;
  color: white;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

/* Notification Badge */
.badge-danger {
  background-color: #ff4d4d;
  font-size: 0.9rem;
  padding: 0.5rem;
  border-radius: 50%;
}
@media (max-width: 768px) {
  .pill-search {
    display: none !important; /* Ensure the rule takes precedence */
  }}